import { Box, Button, Card, HStack, Link, Skeleton, StackDivider, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { Message } from "../types/dynamic-app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import React, { useContext, useRef, useState } from 'react';
import DynamicContent from './sidebar/DynamicContent';
import AutoResizeTextarea from './AutoResizeTextarea';
import { ReactNode } from 'react';
import { StoreContext } from '../store';
import { motion } from "framer-motion";

interface Props {
	id: number;
	message: Message;
}

export default function MessageComponent({ message: el, id: key }: Props) {
	const textRef = useRef<HTMLTextAreaElement>(null);
	const [editing, setEditing] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState(el.collapsed);
	const color = el.role === 'user' ? '#1B2844' : '#FBECE4';
	const assistantColor = '#1B2844';
	const userColor = '#FBECE4';
	const { state: { status }, callAction, actionLoading, setStatus } = useContext(StoreContext);

	const handleToggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
	};

	const truncateText = (text: string, maxLength: number) => {
		if (text.length <= maxLength) return text;
		return text.substr(0, maxLength) + '...';
	};


	interface LinkRendererProps {
		href: string;
		children: ReactNode;
	}

	// Not sure what the actual type is

	const newTheme = {
		p: (props: { children: ReactNode }) => {
			const { children } = props;
			return (
				<Text fontSize={{ base: '16px', md: '19.2px' }} className="disableMargin">
					{children}
				</Text>
			);
		},
		a: (props: { href?: string; children: ReactNode }) => {
			const { href, children } = props;
			return (
				<Link href={href ?? '#'} color="blue.500" textDecoration="underline">
					{children}
				</Link>
			);
		},
	};

	return (
		<HStack
			as={motion.div}
			alignItems="start"
			alignSelf={el.role === 'user' ? 'end' : 'start'}
			spacing={8}
			position="relative"
			maxW={'container.sm'}
			initial={{ x: el.role === 'user' ? 100 : -100, opacity: 0 }}
			animate={{ x: 0, opacity: 1 }}
			exit={{ x: el.role === 'user' ? 100 : -100, opacity: 0 }}
			transition={{ "ease": "linear", duration: "0.5" }}
			w="100%"
			role='group'
		>
			{/* {el.role === 'assistant' && <Box p={2}>
				<FontAwesomeIcon icon="envelope" size='lg' />
			</Box>} */}
			<VStack
				// divider={<StackDivider borderColor={color} />}
				width="100%"
			>
				<Card
					w="100%"
					borderTopRadius={16}
					borderBottomLeftRadius={el.role === 'assistant' ? 0 : 16}
					borderBottomRightRadius={el.role === 'assistant' ? 16 : 0}
					padding="16px"
					color={color}
					shadow="none"
					position="relative"
					backgroundColor={el.color || (el.role === 'assistant' ? assistantColor : userColor)}
				>
					<Box
						position="absolute"
						width="0px"
						height="0px"
						borderStyle="solid"
						borderWidth="0 80px 40px 0"
						borderColor={`transparent ${el.color || (el.role === 'assistant' ? assistantColor : userColor)} transparent transparent`}
						style={{ bottom: -20, right: 0 }}
					/>

					<HStack justifyContent="space-between" width="100%" alignItems="start">
						<Text textAlign="start" width="100%" className="disableMargin">
							{isCollapsed ? (
								<>
									{el.content ? (
										<ReactMarkdown components={ChakraUIRenderer(newTheme)} children={truncateText(el.content || '', 100)} skipHtml />
									) : (
										<Skeleton height={6} width="100%" />
									)}
								</>
							) : (
								<>
									{
										el.content ? (
											<ReactMarkdown components={ChakraUIRenderer(newTheme)} children={el.content} skipHtml />
										) : (
											<Skeleton height={6} width="100%" />
										)
									}
								</>
							)}
						</Text>
						{/* <Button onClick={handleToggleCollapse} variant="ghost" size="sm">
							<FontAwesomeIcon icon={isCollapsed ? 'chevron-down' : 'chevron-up'} width={16} />
						</Button> */}
					</HStack>

					{!isCollapsed && (
						<HStack
							position="absolute"
							display="none"
							bottom={2}
							_groupHover={{ display: 'inline' }}
						>
							{el.modifiable && (
								<Button
									variant="ghost"
									size="sm"
									transition="all 200ms linear"
									onClick={() => setEditing(!editing)}
								>
									<FontAwesomeIcon icon={editing ? 'check' : 'pen-to-square'} width={16} />
								</Button>
							)}
							{el.rateable && (
								<>
									<Button
										variant="ghost"
										size="sm"
										transition="all 200ms linear"
										disabled={actionLoading === 'rate'}
										onClick={() => {
											if (!status.page.variables) status.page.variables = {};
											status.page.variables['rated_key'] = key;
											status.page.variables['rated_value'] = true;
											callAction('rate', false, status);
										}}
										color="green.300"
									>
										<FontAwesomeIcon icon={'thumbs-up'} width={16} />
									</Button>
									<Button
										variant="ghost"
										size="sm"
										transition="all 200ms linear"
										disabled={actionLoading === 'rate'}
										onClick={() => {
											if (!status.page.variables) status.page.variables = {};
											status.page.variables['rated_key'] = key;
											status.page.variables['rated_value'] = false;
											callAction('rate', false, status);
										}}
										color="red.300"
									>
										<FontAwesomeIcon icon={'thumbs-down'} width={16} />
									</Button>
								</>
							)}
						</HStack>
					)}
				</Card>

				{!isCollapsed && el.form && <DynamicContent columns={1} form={el.form} />}
				{/* {!isCollapsed && el.logs && <Logs logs={el.logs} />} */}
			</VStack>
			{/* {el.role === 'user' && <FontAwesomeIcon icon="user" size="lg" />} */}
			{el.content && el.content.length > 0 && editing && (
				<AutoResizeTextarea
					ref={textRef}
					maxW={'container.lg'}
					width={'container.lg'}
					textAlign="start"
					defaultValue={el.content}
					onChange={(e: any) => (el.content = e.target.value)}
				/>
			)}
		</HStack>
	);
}
