import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import StoreProvider from './store';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Apps from './pages/Apps';
import App from './pages/App';
import Loading from './pages/Loading';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import theme from './theme';
import InjectableApp from './pages/InjectableApp';

const rootElement = document.getElementById(process.env.REACT_APP_BUILD_TARGET === 'web' ? 'root' : 'wonka-chat-root') as HTMLElement;
if (!rootElement) throw new Error('Root element not found');

const root = ReactDOM.createRoot(rootElement);


const router = createBrowserRouter([
	{
		path: '/',
		element: <Apps />,
	},
	{
		path: '/loading',
		element: <Loading />,
	},
	{
		path: '/:appName',
		element: <App />,
	}
]);

const tolgee = Tolgee()
	.use(DevTools())
	.use(FormatSimple())
	.init({
		language: 'en',
		apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
		apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
	});

if (process.env.REACT_APP_BUILD_TARGET === 'inject') {
	let appName = rootElement.getAttribute('data-app-name');
	if (!appName) appName = 'ask_westtoer_gs';

	root.render(
		<React.StrictMode>
			<TolgeeProvider
				tolgee={tolgee}
				fallback="Loading..."
			>
				<ChakraProvider theme={theme}>
					<StoreProvider>
						<InjectableApp appName={appName} />
					</StoreProvider>
				</ChakraProvider>
			</TolgeeProvider>
		</React.StrictMode>
	);
} else if (process.env.REACT_APP_BUILD_TARGET === 'web') {
	root.render(
		<React.StrictMode>
			<TolgeeProvider
				tolgee={tolgee}
				fallback="Loading..."
			>
				<ChakraProvider theme={theme}>
					<StoreProvider>
						<RouterProvider router={router} />
					</StoreProvider>
				</ChakraProvider>
			</TolgeeProvider>
		</React.StrictMode>
	);
} else {
	throw new Error('Invalid build target');
}
