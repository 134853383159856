import { Container, useColorModeValue } from "@chakra-ui/react";
import AppContainer from "../components/Container";
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../store";
import Loading from "./Loading";
import { useTranslate } from "@tolgee/react";

interface Props {
  appName?: string;
}

function InjectableApp({ appName }: Props) {
  const { state: { status }, loading, fetchApp, fetchChats, fetchModels } = useContext(StoreContext);
  const { page } = status;

  useEffect(() => {
    fetchApp(appName);
  }, []);

  const [fetchedChats, setFetchedChats] = useState(false);
  useEffect(() => {
    if (!fetchedChats && page && page.type === 'chat') {
      fetchModels();
      fetchChats();
      setFetchedChats(true);
    }
  }, [page]);

  const sendSize = () => {
    console.log('Sending size', document.body.scrollWidth);
    window.parent.postMessage({
      type: 'resize',
      width: document.body.scrollWidth,
      height: document.body.scrollHeight,
    });
  };

  window.addEventListener('load', sendSize);
  window.addEventListener('resize', sendSize);

  if (loading) return <Loading />;
  return (
    <Container maxW='100%'>
      <AppContainer />
    </Container>
  );
}

export default InjectableApp;
