import { fetchEventSource } from "@microsoft/fetch-event-source";
import { App, AppData, CardContent, CardImage, Chat, Element, Message } from "./types/dynamic-app";
import axios from "axios";
import { FC, ReactNode, createContext, useEffect, useState } from "react";
import { useToast } from '@chakra-ui/react';
import { User } from "./types/user";
import { Model } from "./types/model";
import { Log } from "./types/log";

interface State {
	status: AppData;
	apps: App[];
	currentChat?: Chat;
	chats: Chat[];
	models: Model[];
	files: File[];
}

interface StoreContextProps {
	state: State;
	setStatus: (status: AppData) => void;
	callAction: (action: string, streaming: boolean, status: AppData) => Promise<AppData | null>;
	loading: boolean;
	fetchingChats: boolean;
	authenticating: boolean;
	actionLoading: string | undefined | null;
	chatSaving: boolean;
	choices: Map<number, string>;
	addChoiceDelta: (id: number, delta: string) => void;
	clearChoices: () => void;
	setActionLoading: (action: string | undefined | null) => void;
	setState: (state: State) => void;
	addFile: (file: File) => void;
	removeFile: (idx: number) => void;
	talkToChat: (message: string, id?: number) => void;
	fetchApp: (name?: string) => void;
	fetchChat: (id: number) => void;
	fetchApps: () => void;
	fetchChats: () => Promise<void>;
	fetchModels: () => Promise<void>;
	resetState: () => void;
	resetChat: () => void;
	saveChat: () => void;
	setModel: (id: number) => void;
	deleteChat: (id: number) => void;
}

export const StoreContext = createContext<StoreContextProps>({} as StoreContextProps);

// const HIDE_APPS = ['rag', 'mail_copywritter'];
const HIDE_APPS: string[] = [];

const initialChat = {
	context_length: 1000,
	temperature: 0.7,
	top_p: 0.8,
	model: 1,
	messages: [
		{
			role: 'system',
			content: 'You\'re a helpful chat assistant',
		}
	]
};

const StoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [state, setState] = useState<State>({ status: {} as AppData, apps: [], chats: [], models: [], files: [], currentChat: { ...initialChat } as Chat });
	const [authenticating, setAuthenticating] = useState(true);
	const [fetchingChats, setFetchingChats] = useState(true);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState<string | undefined | null>();
	const [chatSaving, setChatSaving] = useState(false);
	const [currentApp, setCurrentApp] = useState('');
	const [choices, setChoices] = useState(new Map<number, string>([]));
	const addChoiceDelta = (id: number, delta: string) => {
		const curValue = choices.get(id) || '';
		choices.set(id, curValue + delta);
		setChoices(choices);
	};

	const clearChoices = () => setChoices(new Map<number, string>());

	const toast = useToast();

	useEffect(() => {
		(async () => {
			try {
				if (process.env.REACT_APP_AUTH) await axios.get<User>(process.env.REACT_APP_API_URL + '/auth/me', { withCredentials: true });
				setAuthenticating(false);
			} catch (error) {
				const { data } = await axios.get<{ authorization_url: string }>(process.env.REACT_APP_API_URL + '/auth/google');
				window.location.href = data.authorization_url;
			}
		})();
	}, []);

	const addFile = (file: File) => {
		setState({
			...state, files: [...state.files, file],
		});
	};

	const removeFile = (idx: number) => {
		setState({
			...state,
			files: state.files.splice(idx),
		});
	};

	const resetState = () => {
		setLoading(true);
		setCurrentApp('');
	}

	const resetChat = () => {
		state.status.page.content.right_upper_panel.message_list = [];
		setState({ ...state, currentChat: initialChat as Chat });
	};

	const talkToChat = async (message: string, id?: number) => {
		if (!state.currentChat) return;
		setActionLoading(state.status.page.content.right_lower_panel.send_button.action);

		state.status.page.content.right_upper_panel.message_list.push({ role: 'user', content: message });
		state.status.page.content.right_upper_panel.message_list.push({ role: 'assistant' });

		const formData = new FormData();
		formData.append('message', message);
		formData.append('model', state.currentChat.model.toString());
		formData.append('temperature', state.currentChat.temperature.toString());
		formData.append('top_p', state.currentChat.top_p.toString());
		formData.append('context_length', state.currentChat.context_length.toString());
		if (state.currentChat.messages) formData.append('prompt', state.currentChat.messages.filter(el => el.role === 'system')[0].content as string);
		if (state.files.length > 0) state.files.forEach((file, idx) => formData.append('files', file));

		const { data: chatData } = await axios.postForm<Chat>(
			process.env.REACT_APP_API_URL + '/chats' + (id ? `/${id}` : ''),
			formData,
			{ withCredentials: true }
		);
		state.status.page.content.right_upper_panel.message_list = chatData.messages ?
			chatData.messages
				.filter(el => el.role !== 'system')
			: [];
		state.currentChat = chatData;
		if (!id) state.chats.push(chatData);
		setState(state);

		const el = document.getElementsByClassName("messages__box")[0];
		if (el) {
			el.scrollTop = el.scrollHeight;
		}

		setActionLoading(null);
	};

	const deleteChat = async (id: number) => {
		setFetchingChats(true);

		await axios.delete<Chat>(process.env.REACT_APP_API_URL + '/chats/' + id, { withCredentials: true });
		if (state.currentChat?.id === id) {
			resetChat();
		}

		await fetchChats();

		setFetchingChats(false);
	};

	const saveChat = async () => {
		setChatSaving(true);

		await axios.put<Chat>(
			process.env.REACT_APP_API_URL + '/chats/' + state.currentChat?.id,
			{ context_length: state.currentChat?.context_length, model: state.currentChat?.model, temperature: state.currentChat?.temperature, top_p: state.currentChat?.top_p, prompt: state.currentChat?.messages?.filter(el => el.role === 'system')[0].content },
			{ withCredentials: true }
		);

		setChatSaving(false);
	}

	const fetchChat = async (id: number) => {
		setFetchingChats(true);

		const { data: chatData } = await axios.get<Chat>(process.env.REACT_APP_API_URL + '/chats/' + id, { withCredentials: true });
		state.status.page.content.right_upper_panel.message_list = chatData.messages ?
			chatData.messages
				.filter(el => el.role !== 'system')
			: [];
		state.currentChat = chatData;
		setState({ ...state, currentChat: chatData });

		setFetchingChats(false);
	};

	const fetchApp = async (name?: string) => {
		setLoading(true);
		const { data: pageData } = await axios.get(process.env.REACT_APP_API_URL + '/get_page' + (name ? `/${name}` : ''));
		state.status = pageData.status;
		setState(state);
		setCurrentApp(name || '');

		setLoading(false);
	};

	const fetchChats = async () => {
		setFetchingChats(true);

		const { data: chatsData } = await axios.get(process.env.REACT_APP_API_URL + '/chats', { withCredentials: true });
		state.chats = chatsData;
		setState(state);

		setFetchingChats(false);
	};

	const fetchModels = async () => {
		setLoading(true);

		const { data: models } = await axios.get<Model[]>(process.env.REACT_APP_API_URL + '/models');
		state.models = models;
		setState(state);

		setLoading(false);
	};

	const fetchApps = async () => {
		setLoading(true);
		const { data: appsData } = await axios.get(process.env.REACT_APP_API_URL + '/get_apps', { withCredentials: !!process.env.REACT_APP_AUTH });
		state.apps = appsData.apps.filter((el: App) => !HIDE_APPS.includes(el.name));
		setState(state);

		setLoading(false);
	};

	const setStatus = (status: AppData) => {
		setState({ ...state, status });
	};

	const setModel = (id: number) => {
		if (state.currentChat) {
			state.currentChat.model = id;
			setState({ ...state, currentChat: state.currentChat });
		}
	}

	// Used to scroll to the bottom when receiving new messages
	const scrollToBottom = () => {
		const el = document.getElementsByClassName("messages__box")[0];
		if (el) {
			el.scrollTo({
				top: el.scrollHeight,
				behavior: 'smooth'
			});
		}
	};

	const callAction = async (action: string, streaming: boolean, status: AppData) => {
		console.log("enter call action");
		console.log(action, streaming, status);
		setActionLoading(action);
		if (!streaming) {
			const { data } = await axios.post<{ status: AppData, message?: string, error?: string }>(process.env.REACT_APP_API_URL + '/on_action/' + currentApp, { status: { ...status, action } });
			if (data.message) {
				toast({
					title: 'Success',
					description: data.message,
					status: 'success',
					position: 'top-right',
					duration: 4500,
				});
			} else if (data.error) {
				toast({
					title: 'Error',
					description: data.message,
					status: 'error',
					position: 'top-right',
					duration: 4500,
				});
			} else {
				setStatus(data.status);

				const el = document.getElementsByClassName("messages__box")[0];
				if (el) {
					el.scrollTop = el.scrollHeight;
				}
			}
			setActionLoading(null);
			return data.status;
		}

		const controller = new AbortController();
		let newStatus = { ...status };
		await fetchEventSource(
			process.env.REACT_APP_API_URL + '/on_action/' + currentApp,
			{
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'same-origin',
				signal: controller.signal,
				headers: {
					'Content-Type': 'application/json',
				},
				openWhenHidden: true,
				body: JSON.stringify({ status: { ...status, action } }),
				onerror() {
					controller.abort();
				},
				onmessage({ event, data }) {

					const messageList = newStatus.page.content.right_upper_panel.message_list;
					if (event === 'status') {
						// Immediately abort sending or listening to new events
						controller.abort();
						setActionLoading(null);
						setStatus(JSON.parse(data).status);
						scrollToBottom();
					} else if (event === 'init_status') {
						newStatus = JSON.parse(data).status;
						setStatus(newStatus);
						scrollToBottom();
					} else if (event === 'message_log') {
						const message = JSON.parse(data);
						if (messageList.length > 0) {
							const logs = messageList[messageList.length - 1].logs;
							if (!logs) messageList[messageList.length - 1].logs = [message];
							else logs.push(message);
							setStatus(newStatus);
						}
					} else if (event === 'data_choice') {
						const dataObj = JSON.parse(data);
						const choiceIdx = dataObj['index'];
						addChoiceDelta(choiceIdx, dataObj['data']);
					} else if (event === 'card') {

						try {
							const parsedData = JSON.parse(data);
							const content = JSON.parse(parsedData["items"]) as CardImage[];
							console.log("the parsed data ");
							console.log(content);
							messageList[messageList.length - 1].card = content;
							newStatus.page.content.right_upper_panel.message_list = messageList;
							console.log("the list changed from card ");
							setStatus(newStatus);
						} catch (error) {
							console.log("error in data ", data);
						}

					} else {
						if (data) {
							enum DataOptions {
								introduction = 'introduction',
								conclusion = 'conclusion',
								items = 'items',
							};
							type Data = { [key in DataOptions]: string | { content: string, index: number } };

							try {
								const message = JSON.parse(data) as Data;
								const currentContent = messageList[messageList.length - 1].content;

								// Check if currentContent is not undefined
								console.log(typeof currentContent, typeof currentContent == 'string');
								if (typeof currentContent == 'string') {
									const content = JSON.parse(currentContent) as CardContent;
									if (message.introduction) {
										if (!content.introduction) content.introduction = message.introduction as string;
										else content.introduction += message.introduction as string;
									} else if (message.conclusion) {
										if (!content.conclusion) content.conclusion = message.conclusion as string;
										else content.conclusion += message.conclusion as string;
									}
									messageList[messageList.length - 1].content = JSON.stringify(content);

									const messageContainer = document.getElementById('message-container');
									if (messageContainer) {
										messageContainer.scrollTop = messageContainer.scrollHeight + 50;
									}
								}
							} catch (error) {
								messageList[messageList.length - 1].content += data;
							}

							newStatus.page.content.right_upper_panel.message_list[messageList.length - 1].content = messageList[messageList.length - 1].content;
							setStatus(newStatus);

							const el = document.getElementsByClassName("messages__box")[0];
							if (el) {
								el.scrollTop = el.scrollHeight;
							}
						}
					}
				},
			}
		);

		return null;
	};

	return (
		<StoreContext.Provider
			value={{ state, setStatus, callAction, loading, actionLoading, setActionLoading, fetchApp, fetchApps, fetchModels, resetState, fetchChats, fetchChat, saveChat, setState, removeFile, addFile, authenticating, chatSaving, fetchingChats, deleteChat, resetChat, talkToChat, setModel, choices, addChoiceDelta, clearChoices }}
		>
			{children}
		</StoreContext.Provider>
	);
};

export default StoreProvider;
