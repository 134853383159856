import { Box, Button, Card, Flex, Grid, HStack, Image, Link, SimpleGrid, Skeleton, StackDivider, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { CardContent, Message } from "../types/dynamic-app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { useContext, useEffect, useRef, useState } from 'react';
import { ReactNode } from 'react';
import { StoreContext } from '../store';
import { AnimatePresence, motion } from "framer-motion";
import DotPulse from "./DotPulse";
import TextAnim from "./typing/TextAnim";

interface Props {
	id: number;
	message: Message;
	loading: boolean;
}

export default function MessageComponent({ message: el, id: key, loading }: Props) {
	const { actionLoading, state: { status }, callAction } = useContext(StoreContext);
	const [isCollapsed, setIsCollapsed] = useState(el.collapsed);
	const color = 'white';
	const assistantColor = '#1B2844';
	const userColor = '#1B2844';

	const truncateText = (text: string, maxLength: number) => {
		if (text.length <= maxLength) return text;
		return text.substr(0, maxLength) + '...';
	};

	// Not sure what the actual type is

	const newTheme = {
		p: (props: { children: ReactNode }) => {
			const { children } = props;
			return (
				<Text fontSize={{ base: '16px', md: '19.2px' }} style={{ margin: '8px' }}>
					{children}
				</Text>
			);
		},
		a: (props: { href?: string; children: ReactNode }) => {
			const { href, children } = props;
			return (
				<Link href={href ?? '#'} css="color: #3282CE !important" textDecoration="underline" target="_blank">
					{children}
				</Link>
			);
		},
	};

	const [cardContent, setCardContent] = useState<CardContent>();

	useEffect(() => {
		if (!el.content) return;
		try {
			const parsedContent: CardContent = JSON.parse(el.content);
			setCardContent(parsedContent);
			if (el.card) {
				console.log("there is some card : ", el.card)

			}
			console.log("the content is : ", el.content);
		} catch {
			console.log("card content error catched")
		}
	}, [el.content]);
	useEffect(() => {
		console.log("new creation")
		console.log(el);
	}, []);

	return (
		<Flex>
			<Card
				as={motion.div}
				borderRadius={16}
				borderBottomLeftRadius={0}
				padding="12px"
				color={color}
				shadow="none"
				position="relative"
				backgroundColor={el.color || (el.role === 'assistant' ? assistantColor : userColor)}
				initial={{ x: el.role === 'user' ? 100 : -100, opacity: 0 }}
				animate={{ x: 0, opacity: 1 }}
				exit={{ x: el.role === 'user' ? 100 : -100, opacity: 0 }}
				transition={{ "ease": "linear", duration: "0.5" }}
				width={cardContent ? '100%' : '128px'}
			>
				<Box
					position="absolute"
					width="0px"
					height="0px"
					borderStyle="solid"
					borderWidth="40px 80px 0 0"
					borderColor={`${assistantColor} transparent transparent transparent`}
					style={{ bottom: -20, left: 0 }}
				/>

				<VStack
					spacing={8}
					position="relative"
					w="100%"
					role='group'
				>
					{
						cardContent && (
							<>
								{
									cardContent?.introduction && (
										<Text
											color={color}
											fontSize={{ base: '16px', md: '19.2px' }}
											w="100%"
										>
											<ReactMarkdown components={ChakraUIRenderer(newTheme)} children={cardContent.introduction} skipHtml />
										</Text>
									)
								}

								{
									el.card && el.card.length > 0 && (
										<SimpleGrid w="100%" minChildWidth='256px' gap={6} paddingInline={8} paddingBottom={4}>
											{
												el.card && el.card.map(el => (
													<Card
														as="a"
														target="_blank"
														rel="noopener noreferrer"
														href={el.link}
														w="100%"
														borderRadius={24}
														position="relative"
														backgroundImage={el.image && el.image !== '' ? `url(${el.image})` : ''}
														backgroundSize="cover"
														minH="200px"
													>
														<Card
															w="100%"
															h="100%"
															minH="200px"
															bg="#182744aa"
															borderRadius={0}
															p={4}
															fontWeight="bolder"
															fontSize="large"
															userSelect="none"
															cursor={el.link && "pointer"}
															gap={2}
															overflow="hidden"
														>
															<AnimatePresence>
																<Text
																	fontSize="x-large"
																	fontWeight={600}
																	textColor="white"
																	textAlign="center"
																	w="100%"
																>
																	{el.title}
																</Text>
																<Text
																	fontSize="19.2px"
																	textColor="gray.300"
																	noOfLines={6}
																>
																	{el.description}
																</Text>
															</AnimatePresence>
														</Card>

														<Box
															h="4px"
															w="96px"
															bg="#EB5947"
															position="absolute"
															bottom={0}
															left="calc(50% - 48px)"
														/>
													</Card>
												))
											}
										</SimpleGrid>
									)
								}

								{
									cardContent?.conclusion && cardContent.conclusion.length > 0 && (
										<Text
											color={color}
											fontSize="larger"
											w="100%"
										>
											{/* <ReactMarkdown components={ChakraUIRenderer(newTheme)} children={cardContent.conclusion} skipHtml /> */}
											<TextAnim text={cardContent.conclusion} />
										</Text>
									)
								}

								{!isCollapsed && (
									<HStack
										position="absolute"
										display="none"
										bottom={2}
										right={2}
										_groupHover={{ display: 'inline' }}
									>
										{el.rateable && (
											<>
												<Button
													variant="ghost"
													transition="all 200ms linear"
													padding="8px"
													disabled={actionLoading === 'rate'}
													onClick={() => {
														if (!status.page.variables) status.page.variables = {};
														status.page.variables['rated_key'] = key;
														status.page.variables['rated_value'] = "true";
														callAction('rate', false, status);
													}}
													color="green.300"
												>
													<FontAwesomeIcon style={{ width: '16px', height: '16px' }} icon={'thumbs-up'} />
												</Button>
												<Button
													variant="ghost"
													transition="all 200ms linear"
													padding="8px"
													disabled={actionLoading === 'rate'}
													onClick={() => {
														if (!status.page.variables) status.page.variables = {};
														status.page.variables['rated_key'] = key;
														status.page.variables['rated_value'] = "false";
														callAction('rate', false, status);
													}}
													color="red.300"
												>
													<FontAwesomeIcon style={{ width: '16px', height: '16px' }} icon={'thumbs-down'} />
												</Button>
											</>
										)}
									</HStack>
								)}
							</>
						)
					}
					{
						loading && (
							<DotPulse />
						)
					}
				</VStack>
			</Card>
		</Flex>
	);
}
