import { StoreContext } from "../store";
import { Box, Container, Flex, HStack, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import Chatbox from "./ChatBox";
import Message from "./Message";
import CardMessage from "./CardMessage";
import { AnimatePresence } from "framer-motion";

export default function AppContainer() {
	const { state: { status: { page }, status }, choices, setStatus, clearChoices, actionLoading } = useContext(StoreContext);
	const color = useColorModeValue('gray.300', 'gray.700');

	const selectChoice = (body: string) => {
		const message_list = page.content.right_upper_panel.message_list;
		message_list.push({ role: 'assistant', content: body });
		setStatus(status);
		clearChoices();
	};
	useEffect(() => {
		console.log("the message list change");
		console.log(status.page.content.right_upper_panel.message_list)
	}, [status.page.content.right_upper_panel.message_list]);

	// Scroll down #container all the time
	// Scroll so that #disclaimer is at the bottom of the container
	useEffect(() => {
		const container = document.getElementById('container');
		const messageContainer = document.getElementById('message-container');
		if (container && messageContainer) {
			const scrollAmount = container.scrollHeight - container.clientHeight;
			messageContainer.style.paddingTop = `${scrollAmount}px`;
			container.scrollTop = messageContainer.scrollHeight;
		}
	}, [page.content.right_upper_panel.message_list]);
	return (
		<>
			<AnimatePresence>
				{
					page.content.right_upper_panel.message_list.length === 0 ? (
						<VStack
							h="100%"
							maxW="100vw"
							maxH="100vh"
							spacing={24}
							paddingTop={[6, 24]}
						// paddingBottom={12}
						>
							{
								!page.content.right_lower_panel.hidden && (
									<Chatbox />
								)
							}
						</VStack>

					) : (
						<VStack
							id="container"
							maxW="100vw"
							maxH="100vh"
							spacing={6}
							paddingTop={[6, 24]}
							// paddingBottom={12}
							overflow="hidden"
						>
							{
								[
									<Container
										className="messages__box"
										maxW={'container.xl'}
									>
										<Flex
											// divider={<StackDivider borderColor={color} />}
											id="message-container"
											direction="column"
											gap={6}
											maxH={["60vh"]}
											overflowY="auto"
										>
											<AnimatePresence>
												{
													page.content.right_upper_panel.message_list.map((el, idx) => (
														el.card_format ? (
															<Box paddingRight={[0, 12]}>
																<CardMessage key={idx} id={idx} message={el} loading={false} />
															</Box>
														) : (
															<Flex direction="column" paddingLeft={el.role === 'user' ? 12 : 0} paddingRight={el.role === 'assistant' ? 12 : 0}>
																<Message key={idx} id={idx} message={el} />
															</Flex>
														)
													))
												}
												{
													actionLoading === 'send_prompt' && page.content.right_upper_panel.message_list[page.content.right_upper_panel.message_list.length - 1].role === 'user' && (
														<Box paddingRight={[0, 12]}>
															<CardMessage id={-1} message={{ role: 'assistant' }} loading={true} />
														</Box>
													)
												}
											</AnimatePresence>

											{
												Object.keys(Object.fromEntries(choices)).length > 0 && (
													<HStack
														alignItems='stretch'
													>
														{
															Object.values(Object.fromEntries(choices)).map((el, idx) => (
																<Box
																	w="50%"
																	rounded="lg"
																	flex="1"
																	p={4}
																	borderColor={color}
																	cursor="pointer"
																	_hover={{ backgroundColor: color }}
																	borderWidth="1px"
																	onClick={() => selectChoice(el)}
																>
																	<Text fontSize="large" fontWeight="bolder" mb={1.5}>Response {idx + 1}</Text>
																	<Text>{el}</Text>
																</Box>
															))
														}
													</HStack>
												)
											}
										</Flex>
									</Container>,
									<>
										{
											!page.content.right_lower_panel.hidden && (
												<Chatbox />
											)
										}
									</>
								]
							}
						</VStack>
					)
				}
			</AnimatePresence >
		</>
	);
}
